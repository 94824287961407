<template>
  <v-data-table :headers="headers" :items="items" sort-by="no" @click:row="onClickRow">
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn color="success" dark rounded :to="{ name: 'Create Bank Statement' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ items.findIndex(x => x === item) + 1 }}
    </template>
    <template v-slot:item.amount="{ item }">
      {{ formatPrice(item.amount) }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Date", value: "date", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Transaction Code", value: "transactionCode", sortable: false },
      { text: "Type", value: "type", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Bank", value: "bankName", sortable: false },
    ],
    items: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bankStatement/getAll")
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Bank Statement", params: { id: item.id } });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style></style>
